<template>
    <div class="c-thrift-catch-all-page">
        <SlideLayout :closeLink="null" :logoLink="null">
            <template #logo>
                <CommonHeaderLogo />
            </template>
            <ContentfulContentBlock
                :contentRows="contentRows"
                :contentTitle="contentTitle"
            />
        </SlideLayout>
    </div>
</template>

<script setup>
import { ref, onBeforeMount, computed } from 'vue';

import { useLogger } from '~coreModules/core/js/composables/useLogger';
import { useContentfulSvc } from '~coreModules/core/js/composables/useContentfulSvc';
import { getContentfulPageContentForSlug } from '~coreModules/contentful/js/contentful-client';
import { shapeContentfulContentPageResponse } from '~coreModules/contentful/js/contentful-utils';

import { CONTENT_TYPES, CONTENT_SLUGS } from '~modules/contentful/js/contentful-constants';

import SlideLayout from '~modules/core/components/layouts/SlideLayout.vue';
import CommonHeaderLogo from '~modules/header/components/CommonHeaderLogo.vue';
import ContentfulContentBlock from '~modules/contentful/components/ContentfulContentBlock.vue';

const logger = useLogger();
const contentfulSvc = useContentfulSvc();

const content = ref({});
const contentRows = computed(() => content.value?.rows || []);
const contentTitle = computed(() => content.value?.title || '');

function fetchContentfulContent() {
    return getContentfulPageContentForSlug({
        contentfulSvc,
        slug: CONTENT_SLUGS.THRIFT_CATCH_ALL_PAGE,
        contentType: CONTENT_TYPES.CONTENT_SLOT,
    })
        .then((res) => {
            if (!res) {
                throw Error(`No content is authored for ${CONTENT_SLUGS.THRIFT_CATCH_ALL_PAGE}`);
            }
            content.value = shapeContentfulContentPageResponse(res);
        })
        .catch((error) => {
            logger.debugError('Failed to fetch contentful content: ', error);
        });
}

onBeforeMount(() => (
    fetchContentfulContent()
        .catch(error => logger.debugError(error))
));
</script>

<style lang="scss">

</style>
